@media (min-width: 750px){
    .yqljBox{
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        background-color: rgb(240, 240, 240);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        :global{
            .link{
                font-size: 18px;
                margin: 10px 15px;
                cursor: pointer;
                color: rgb(120, 194, 240);
            }
        }
    }
}


@media (max-width: 750px){
    .yqljBox{
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        :global{
            .link{
                font-size: .28rem;
                margin: .1rem .15rem;
                cursor: pointer;
                color: rgb(120, 194, 240);
            }
        }
    }
}

/* For demo */
.swiper-container-common {
  width: 100% !important;
  height: 100%;
}

.swiper-container-common .swiper-slide {
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

}
.jxhd_section_p{
  width:100%;
  height:170px;
  position:absolute;
  left:0;
  bottom:0;
  display:flex;
  padding:10px 35px;
  box-sizing:border-box;
  color:#fff;
  font-size:18px;
  align-items:flex-end;
  background-size: 100% 100%!important;
}
.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper img {
  width: 100%;
  height:100%;
  object-fit: cover;
}
.swiper-slide img {
  width: 100%;
  height:auto;
}
.swiper-pagination {
  opacity: 0;
}

.opa {
  opacity: 1;
}
.quhdSlider{
  width:100%;
  overflow: hidden;
}
.swiper-top{
  height:575px;
  position: relative;
  border-radius: 10px;
}
.gallery-thumbs{
  width:100%;
  height:70px;
  margin-top: 10px;

}

.gallery-thumbs .swiper-slide,.swiper-top .swiper-slide,.swiper-M .swiper-slide{
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.gallery-thumbs .swiper-slide{
  border-radius: 5px;
}
@media(min-width:750px){
  .gallery-thumbs,.swiper-top{
    display: block;
  }
}
@media(max-width:750px){
  .gallery-thumbs,.swiper-top{
    display: none;
  }
  .swiper-M{
    width:100%;
    height:4rem;
  }
}

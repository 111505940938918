.unionList{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    :global{
        p{
            margin: 0;
            padding: 0;
        }
    }
}
.conBj{
    width: 100%;
    height: 100%;
    background-size: 100%!important;
    background-repeat: no-repeat;
    background: url('/img/union/conBj.png');
    background-position: left bottom;
    background-repeat:no-repeat;
    background-color: #efefef;
}
// 面包屑
.crumbs{
    width: 100vw;
    height: 50px;
    background-color: #f2f2f2;
    :global{
        .crumbsCon{
            width: 1200px;
            height: 50px;
            line-height: 50px;
            margin: 0 auto;
            text-align: left;
            font-size: 18px;
            span{
                color: rgb(0, 161, 180);
            }
        }
    }
}



// 列表内容
.listCon{
    width: 1403px;
    margin: 0 auto;
    :global{
        .btnGroup{
            padding-top: 15px;
            p{
                display: inline-block;
                padding: 0px 20px;
                height: 35px;
                font-size: 18px;
                line-height: 35px;
                border: 1px solid rgb(0, 156, 190);
                color: rgb(0, 156, 190);
                cursor: pointer;
                margin-right: 10px;
                border-radius: 6px;
            }
            .btnActive{
                border: 1px solid rgb(255, 255, 255);
                color: rgb(255, 255, 255);
                background: rgb(0, 156, 190);
                border-radius: 6px;
            }
        }
        a{
            color:inherit;
        }
        .columTitle{
            width: 100%;
            // padding-left: 20px;
            font-size: 28px;
            color: #0c81c7;
            line-height: 50px;
            font-weight: 500;
            position: relative;
            border-bottom: 1px solid #dddddd;
            &:before{
               content: '';
               width: 2px;
               height: 28px;
               background-color: #0c81c7;
               display: inline-block;
               position: absolute;
               top: 12px;
            }
        }
        .paginationBox{
            margin-top: 20px;
            width: 100%;
            height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #dddddd;
            .turnBtn{
                width: 12px;
                height: 12px;
                line-height: 24px;
                text-align: center;
                color: #999;
                font-weight: bold;
            }
            .ant-pagination-options-quick-jumper input,
            .ant-pagination-item{
                width: auto;
                height: 22px;
                line-height: 22px;
                border: none;
                background: none;
                min-width: 22px;
            }
            .ant-pagination-options-quick-jumper,
            .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
                height: auto;
                font-size: 12px;
            }
            .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
                min-width: 0;
            }
            .ant-pagination-options-quick-jumper input {
                height: 24px;
                padding: 1px 7px;
                width: 44px;
                position: relative;
                display: inline-block;
                width: 100%;
                padding: 4px 11px;
                color: rgba(0,0,0,.65);
                font-size: 14px;
                background-color: #fff;
                background-image: none;
                border: 1px solid #d9d9d9;
                border-radius: 4px;
                -webkit-transition: all .3s;
                transition: all .3s;
                width: 50px;
                margin: 0 8px;
            }
            .ant-pagination-item-active {
                background-color: rgb(0, 156, 190);
                color: #fff;
                a {
                    color: #fff;
                }
            }
        }
    }
}

// 馆际交流
.gjjlItem{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    :global{
        img{
            width: 290px;
            height: 175px;
            object-fit: cover;
            border-radius: 3px;
        }
        .conBox{
            width: 598px;
            font-size: 16px;
            .title{
                font-size: 18px;
                color: #333333;
            }
            .msg{
                color: #999999;
                margin: 20px auto;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
            .timeBox{
                display: flex;
                color: #999999;
                justify-content: space-between;
            }
        }
    }
}

// 精彩视频
.jcspItemYd{
    display: none;
}
.jcspItem{
   width: 423px;
   overflow: hidden;
   display: inline-block; 
   margin-top: 22px;
   cursor: pointer;
   :global{ 
        img{
            width: 100%;
            height: 306px;
            object-fit: cover;
        }
        .conBox{
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            padding: 25px;
            background-color: #f7f7f7;
            .time{
                padding: 5px 10px;
                display: inline-block;
                color: #fff;
                font-size: 16px;
                background-color: #0c81c7;
            }
            .title{
                font-size: 16px;
                margin-top: 0px;
                overflow: hidden;
                color: #333333;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }
   }
}
.jchdItemYd{
    display: none;
}
.jchdItem{
    width: 448px;
    height: 257px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 22px;
    position: relative;
    cursor: pointer;
    &:hover {
        :global{
            .mooter{
                top: 0!important;
            }
        }
    }
    :global{
        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .mooter{
            position: absolute;
            top: 257px;
            left: 0;
            background: rgba(24,97,174,.8);
            width: 448px;
            height: 257px;
            color: #fff;
            overflow: hidden;
            z-index: 2;
            .title{
               font-size: 30px;
               font-weight: 600; 
               width: 80%;
               margin: 0 auto;
               text-align: center;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
               margin: 80px auto 30px;
            }
            .btn{
                width: 105px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                font-size: 16px;
                margin: 0 auto;
                border: 1px solid #fff;
            }
        }
    }
}
//详情页
.contentStyle{
    width: 1100px;
    margin: 0 auto;
    :global{
        .title{
            font-size: 30px;
            margin: 50px auto 0px;
            text-align: center;
        }
        .time{
            margin: 20px auto;
            text-align: center;
            padding-bottom: 30px;
            border-bottom: 1px solid #dddddd;
        }
        .main{
            width: 100%;
            p{
                font-size: 16px;
                text-indent: 2em;
            }
            img{
                max-width: 800px;
                margin: 30px auto;
                display: block;
            }
            
        }
        .listBox{
            padding: 50px 0;
            position: relative;
            border-top: 1px solid #dddddd;
            p{
                cursor: pointer;
            }
            .btn{
                width: 220px;
                margin: 0 auto;
            }
            .ljfhBtn, .linkBtn{
                width: 100px;
                height: 40px;
                color: #fff;
                font-size: 18px;
                background: rgb(0, 161, 180);
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                border-radius: 5px;
                margin: 0 auto;
                float:left;
            }
            .linkBtn{
                margin-left: 20px;
                a{
                    color: #fff;
                }
            }
        }
    }
}
.title_type_1{
            font-size:32px;
            font-weight:bold;
            line-height:32px;
            color:#fff;
            display:flex;
            flex-direction:row;
            align-items:center;
            .i_small2{
                        width:8px;
                        height:8px;
                        background:#f55c5d;
            }
            .i_large2{
                        width:16px;
                        height:16px;
                        background:#00a1b4;   
                        margin-right:7px;             
            }            
            span{
                        padding:10px 25px;
                        background:#00a1b4;
                        border-radius:5px;
                        margin:0 16px 0 0;
                        box-sizing:border-box;
            }
        }


// 移动端
@media (max-width: 750px) {
    .unionList{
        width: 100%;
        margin: 0 auto;
        padding: 0;
        display: block;
        :global{
            p{
                margin: 0;
                padding: 0;
            }
        }
    }
    .conBj{
        width: 100%;
        height: 100%;
        background: none;
        background-color: #fff;
    }
    // 面包屑
    .crumbs{
        width: 100vw;
        height: .6rem;
        background-color: #f2f2f2;
        :global{
            .crumbsCon{
                font-size: .24rem;
                padding-left: .2rem;
                width: 100%;
                height: .6rem;
                line-height: .6rem;
                margin: 0 auto;
                text-align: left;
            }
        }
    }
    
    
    // 列表内容
    .listCon{
        width: 7.08rem;
        margin: 0 auto;
        :global{
            .btnGroup{
                display: none;
            }
            .columTitle{
                font-size: .36rem;
                color: #0c81c7;
                line-height: 1rem;
                position: relative;
                &:before{
                   content: '';
                   width: 2px;
                   height: .36rem;
                   background-color: #0c81c7;
                   display: inline-block;
                   position: absolute;
                   top: .3rem;
                   display: block;
                }
            }
            .paginationBox{
                margin-top: .2rem;
                width: 100%;
                height: 1.1rem;
                .turnBtn{
                    font-size: .22rem;
                    width: 1rem;
                    height: .5rem;
                    min-width: 0px;
                    line-height: .8rem;
                }
                .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
                    min-width: 0px;
                    margin-right: .1rem;
                }
                .ant-pagination-options-quick-jumper input,
                .ant-pagination-item{
                    width: .5rem;
                    height: .5rem;
                    margin-right: .1rem;
                    line-height: .5rem;
                    min-width: 0!important;
                    font-size: .22rem;
                }
                .ant-pagination-options-quick-jumper,
                .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
                    height: auto;
                    font-size: 16px;
                }
            }
        }
    }
    
    // 馆际交流
    .gjjlItem{
        margin-top: .2rem;
        cursor: pointer;
        :global{
            img{
                width: 2.9rem;
                height: 1.75rem;
            }
            .conBox{
                width: 3.8rem;
                font-size: .22rem;
                .title{
                    font-size: .26rem;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .msg{
                    margin: .15rem auto;
                }
            }
        }
    }
    
    // 精彩视频
    .jcspItem{
        display: none;
    }
    .jcspItemYd{
       width: 3.42rem;
       overflow: hidden;
       display: inline-block; 
       margin-top: .22rem;
       cursor: pointer;
       :global{ 
            img{
                width: 100%;
                height: 4.2rem;
                object-fit: cover;
            }
            .conBox{
                width: 100%;
                height: 1.6rem;
                box-sizing: border-box;
                padding: .2rem;
                background-color: #f7f7f7;
                .time{
                    padding: .05rem .1rem;
                    display: inline-block;
                    color: #fff;
                    font-size: .22rem;
                    background-color: #0c81c7;
                }
                .title{
                    font-size: .24rem;
                    margin-top: .15rem;
                    overflow: hidden;
                    color: #333333;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
            }
       }
    }
    
    .jchdItem{
        display: none;
    }
    .jchdItemYd{
        width: 3.44rem;
        height: 2rem;
        display: inline-block;
        overflow: hidden;
        margin-bottom: .15rem;
        position: relative;
        cursor: pointer;
        &:hover {
            :global{
                .mooter{
                    top: 0!important;
                }
            }
        }
        :global{
            
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .mooter{
                position: absolute;
                top: 2rem;
                left: 0;
                background: rgba(24,97,174,.8);
                width: 3.44rem;
                height: 2rem;
                color: #fff;
                overflow: hidden;
                z-index: 2;
                .title{
                   font-size: .32rem;
                   font-weight: 600; 
                   width: 90%;
                   margin: 0 auto;
                   text-align: center;
                   overflow: hidden;
                   white-space: nowrap;
                   text-overflow: ellipsis;
                   margin: .5rem auto .3rem;
                }
                .btn{
                    width: 1.05rem;
                    height: .35rem;
                    line-height: .35rem;
                    text-align: center;
                    font-size: .22rem;
                    margin: 0 auto;
                    border: 1px solid #fff;
                }
            }
        }
    }
    
    
    //详情页
    .contentStyle{
        width: 7.08rem;
        margin: 0 auto;
        :global{
            .title{
                font-size: .3rem;
                margin: .5rem auto 0px;
                text-align: center;
            }
            .time{
                margin: .2rem auto;
                text-align: center;
                padding-bottom: .3rem;
                border-bottom: 1px solid #dddddd;
            }
            .main{
                width: 100%;
                p{
                    font-size: .26rem;
                }
                img{
                    max-width: 7.08rem;
                    margin: .3rem auto;
                    display: block;
                }
                
            }
            .listBox{
                padding: .5rem 0;
                position: relative;
                font-size: .26rem;
                border-top: 1px solid #dddddd;
                p{
                    cursor: pointer;
                }
                .ljfhBtn{
                    width: 2rem;
                    height: 0.8rem;
                    background: rgb(0, 161, 180);
                    margin: 0.2rem auto 0.2rem;
                    font-size: 0.35rem;
                    color: rgb(255, 255, 255);
                    text-align: center;
                    line-height: 0.8rem;
                    border-radius: 0.1rem;
                    cursor: pointer;
                    display: block;
                    position: static;
                }
            }
        }
    }
}
@media (min-width: 750px){
    .videoBox{
        width: 100%;
        margin: 0 auto 20px;
        background-color: rgb(240, 240, 240);
        padding: 20px;
        box-sizing: border-box;
        border-radius: 8px;
        :global{
            video{
                width: 800px;
                height: 450px;
                object-fit: cover;
                border-radius: 8px;
            }
            .right{
                width: 380px;
                height: 450px;
                box-sizing: border-box;
                padding: 50px 0;
                p{
                    cursor: pointer;
                    font-size: 18px;
                    height: 30px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                }
                .active,
                p:hover{
                    color: rgb(0, 156, 190);
                    font-weight: 600;
                }
            }
        }
    }
}


@media (max-width: 750px){
    .videoBox{
        width: 100%;
        margin: 0 auto 20px;
        display: block;
        :global{
            video{
                width: 100%;
                height: 4.5rem;
                object-fit: cover;
                border-radius: .08rem;
            }
            .right{
                width: 100%;
                height: 4rem;
                overflow-y: auto;
                box-sizing: border-box;
                padding: .1rem 0;

                p{
                    cursor: pointer;
                    font-size: .28rem;
                    height: .5rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    white-space: nowrap;
                }
                .active,
                p:hover{
                    color: rgb(0, 156, 190);
                    font-weight: 600;
                }
            }
        }
    }
}

@media (min-width: 750px){
    .threeLine{
        width: 100%;
        background-color: rgb(240, 240, 240);
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        box-shadow: border-box;
        border-radius: 8px;
        :global{
            .threeItem{
                width: 390px;
                margin-right: 15px;
                margin-bottom: 35px;
                cursor: pointer;
                overflow: hidden;
                img{
                    width: 100%;
                    border-radius: 8px;
                    height: 250px;
                    object-fit: cover;
                }
                .title{
                    width: 100%;
                    height: 55px;
                    font-size: 18px;
                    margin:15px 0;
                    display: -webkit-box;  
                    -webkit-line-clamp: 2;  
                    -webkit-box-orient: vertical;    
                    overflow: hidden;  
                    text-overflow: ellipsis;  
                    word-wrap: break-word; 
                }
                .time{
                    color: #999;
                }
            }
            .threeItem:nth-of-type(3n){
                margin-right: 0;
            }
        }
    }
}


@media (max-width: 750px){
    .threeLine{
        width: 100%;
        margin: 0 auto .2rem;
        display: flex;
        flex-wrap: wrap;
        :global{
            .threeItem{
                width: 3.5rem;
                margin-right: 0.3rem;
                margin-bottom: .35rem;
                cursor: pointer;
                overflow: hidden;
                img{
                    width: 100%;
                    border-radius: .08rem;
                    height: 2.1rem;
                    object-fit: cover;
                }
                .title{
                    width: 100%;
                    height: .82rem;
                    font-size: .28rem;
                    margin:.15rem 0;
                    display: -webkit-box;  
                    -webkit-line-clamp: 2;  
                    -webkit-box-orient: vertical;    
                    overflow: hidden;  
                    text-overflow: ellipsis;  
                    word-wrap: break-word; 
                }
                .time{
                    color: #999;
                }
            }
            .threeItem:nth-of-type(2n){
                margin-right: 0;
            }
        }
    }
}

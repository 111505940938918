.curatorSession{
    width: 1342px;
    margin: 0 auto;
    :global{
        p{
            margin: 0;
            padding: 0;
        }
    }
}

.diaBox{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 2000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    :global{
        .tst{
            display: block;
            height: 80vh;
        }
        .close{
            width: 3vw;
            height: 3vw;
            align-self: flex-start;
            margin-top: 8vh;
            margin-left: -1.5vw;
            cursor: pointer;
            background-color: #fff;
            border-radius: 50%;
            z-index: 10;
        }
    }
}

.indexCrumbs{
    width: 100%;
    height: 50px;
    background: none;
    :global{
    .crumbsCon{
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin: 0 auto;
            text-align: left;
            font-size: 18px;
            span{
                color: rgb(0, 161, 180);
            }
        }
    }
}
//悬浮按钮
.floatImg1,
.floatImg{
    position: fixed;
    top: 40vh;
    width: 130px;
    height: 130px;
    cursor: pointer;
    z-index: 99;
}
.floatImg{
    left: 50%;
    margin-left: -810px;
}
.floatImg1{
    left: 50%;
    margin-left: 680px;
}

.title_type_1{
    font-size:28px;
    font-weight:bold;
    line-height:28px;
    color:#fff;
    display:flex;
    flex-direction:row;
    align-items:center;
    .i_small2{
        width:8px;
        height:8px;
        background:#f55c5d;
    }
    .i_large2{
        width:16px;
        height:16px;
        background:#00a1b4;   
        margin-right:7px;             
    }            
    span{
        padding:10px 25px;
        background:#00a1b4;
        border-radius:5px;
        margin:0 16px 0 0;
        box-sizing:border-box;
    }
}
// topSwiper
.topSwiper{
    width: 100%; 
    height: 425px;
    background-color: #f7f7f7;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    :global{
        .swiperBox{
            width: 841px;
            position: relative;
            .turnBtn{
                width: 35px;
                height: 50px;
                background: rgba(0,0,0,.5);
                cursor: pointer;
                color: #fff;
                line-height: 50px;
                text-align: center;
                font-size: 30px;
                position: absolute;
                top: 190px;
                z-index: 2;
            }
            .turnBtn-l{
                left: 0;
            }
            .turnBtn-R{
                right: 0;
            }
            .topSwiper-slide{
                height: 425px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .curatorTopSwText{
                    width: 100%;
                    height: 55px;
                    background: rgba(0,0,0,.5);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    color: #fff;
                    font-size: 18px;
                    line-height: 55px;
                    box-sizing: border-box;
                    padding: 0 20px;
                }
            }
            .ant-carousel .slick-dots-bottom{
                text-align: right;
                bottom: 30px;
                right: 20px;
            }
            .ant-carousel .slick-dots li button{
                width: 10px;
                height: 10px;
                border-radius: 5px;
            }
        }
        .topSwiperMsgBox{
            width: 501px;
            height: 100%;
            box-sizing: border-box;
            padding: 20px;
            
            .topSwiperMsg-title{
                font-size: 30px;
                text-align: center;
                margin-bottom: 20px;
                color: #333333;
            }
            .topSwiperMsg-msg{
                text-align: justify;
                font-size: 18px;
                line-height: 30px;
                color: rgb(0, 0, 0);
                margin-top: 10px;
            }
        }
    }
    
}


// 新闻中心
.curatorNewsCenter{
    width: 100%;
    height: 600px;
    background-image: url('/img/curatorSession/newsCenterBj.jpg');
    background-size: 100% 100%;
    overflow: hidden;
    :global{
        .flex-center{
            display: flex;
            justify-content: center;
        }
        .flex-sb{
            display: flex;
            justify-content: space-between;
        }
        .title{
            font-size: 36px;
            text-align: center;
            margin: 35px auto;
            color: #333333;
            font-weight: 600;
            width: 100%;
            position: relative;
            .link{
                position: absolute;
                right: 20px;
                top: 10px;
                color: #fff;
                background-color: rgb(0, 161, 180);
                font-size: 18px;
                padding: 3px 10px;
                border-radius: 5px;
                font-weight: normal;
                line-height: 24px;
            }
        }
        .btnItem{
            width: 190px;
            height: 45px;
            border-radius: 22.5px;
            background-color: #fff;
            text-align: center;
            line-height: 45px;
            margin: 0 20px;
            font-size: 20px;
            cursor: pointer;
        }
        .btnItem-active,
        .btnItem:hover{
            background-color: #0c81c7;
            color: #fff;
        }
        .contentBox{
            width: 1330px;
            margin: 28px auto;
            height: 370px;
            display: flex;
            .conLeft{
                width: 775px;
                .leftItem{
                    width: 380px;
                    height: 370px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 220px;
                        object-fit: cover;
                    }
                    .msgBox{
                        width: 100%;
                        height: 150px;
                        background-color: #fff;
                        overflow: hidden;
                        padding: 10px;
                        box-sizing: border-box;
                        .title{
                            font-size: 18px;
                            margin: 0;
                            text-align: left;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                        .msg{
                            text-align: justify;
                            font-size: 16px;
                            color: #666666;
                            margin: 10px auto;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient:vertical;
                        }
                        .flex-jus{
                            display: flex;
                            justify-content: space-between;
                            font-size: 18px;
                            .ckyq{
                                color: #0c81c7;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .conRight{
                width: 530px;
                background-color: #fff;
                box-sizing: border-box;
                padding: 0 15px;
                .rightItem:last-child{
                    border: 0;
                }
                .rightItem{
                    width: 100%;
                    align-items: center;
                    cursor: pointer;
                    padding: 21px 0;
                    border-bottom: 1px solid #e5e5e5;
                    .dateBox{
                        width: 80px;
                        height: 80px;
                        background-color: #0c81c7;
                        border-radius: 2px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        .bigSize{
                            font-size: 36px;
                        }
                    }
                    .title{
                        width: 390px;
                        font-size: 16px;
                        margin: 0;
                        text-align: left;
                    }
                    .btn{
                        width: 35px;
                        height: 35px;
                        border-radius: 17.5px;
                        background-color: #e5e5e5;
                        text-align: center;
                        line-height: 35px;
                    }
                }
                .rightItem:hover{
                    .title{
                        color: #0c81c7;
                    }
                    .btn{
                        color: #fff;
                        background-color: #0c81c7;
                    }

                }
            }
        }
    }
}

// 视频选择框
.videoSelect{
    width: 1342px;
    height: 520px;
    margin: 50px auto;
    display: flex;

    :global{
        .videoBox{
            width: 918px;
            height: 520px;
            background-color: #333333;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .videoList{
            width: 424px;
           
            .iconImg{
                color: #0c81c7;
                position: absolute;
                font-size: 25px;
                left: -17px;
                top: 25px;
                display: none;
            }
            .videoItem{
                position: relative;
                width: 100%;
                height: 74.3px;
                display: flex;
                background-color: #f6f6f6;
                font-size: 22px;
                line-height: 38px;
                padding-left: 23px;
                align-items: center;
                cursor: pointer;
                word-break: keep-all;
                img{
                    width: 30px;
                    // height: 30px;
                    margin-right: 20px;
                }
            }
            .videoActive{
                background-color: #0c81c7!important;
                color: #fff;
            }
            .videoItem:hover{
                background-color: #0c81c7!important;
                color: #fff;
            }
            .videoItem:hover .iconImg{
                display: block;
            }
            .videoItem:nth-last-of-type(odd){
                background-color: #ececec;
            }
        }
    }
}


// 发言嘉宾
.honouredGuest{
    width: 1342px;
    height: 530px;
    margin: 50px auto 0;
    position: relative;
    background-image: url('/img/curatorSession/honouredGuestBj.jpg');
    :global{
        .title{
            color: #fff;
            font-size: 36px;
            text-align: center;
            font-weight: 500;
            line-height: 100px;
            position: relative;
            .link{
                position: absolute;
                right: 20px;
                top: 40px;
                color: #fff;
                background-color: rgb(0, 161, 180);
                font-size: 18px;
                padding: 3px 10px;
                border-radius: 5px;
                font-weight: normal;
                line-height: 24px;
            }
        }
        .turnBtn{
            width: 34px;
            height: 48px;
            background: rgba(0,0,0,.8);
            cursor: pointer;
            line-height: 48px;
            color: #fff;
            text-align: center;
            font-size: 30px;
            position: absolute;
            top: 245px;
            left: 0;
            z-index: 2;
        }
        .turnBtn-R{
            right: 0;
            left: auto;
        }
        .swiperBox-yd{
            display: none;
        }
        .swiperBox{
            width: 1320px;
            height: 360px;
            margin: 0 auto;
            display: block;
            .swiper-slide{
                width: 254px;
                height: 360px!important;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .text{
                    background-color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 55px;
                    line-height: 20px;
                    padding-top: 5px;
                    text-align: center;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
// 推荐会
.sellMetting{
    width: 1342px;
    background-color: #d9f1ff;
    :global{
        .title{
           font-size: 36px;
           font-weight: 600; 
           text-align: center;
           line-height: 100px;
           position: relative;
            .link{
                position: absolute;
                right: 20px;
                top: 40px;
                color: #fff;
                background-color: rgb(0, 161, 180);
                font-size: 18px;
                padding: 3px 10px;
                border-radius: 5px;
                font-weight: normal;
                line-height: 24px;
            }
        }
        .bigImg-yd,.swiperBox-yd{
            display: none;
        }
        .bigImg{
            width: 1045px;
            height: 610px;
            overflow: hidden;
            margin: 0 auto;
            display: block;
            position: relative;
            .swiper-wrapper img{
                width: 1045px;
                height: 610px;
                object-fit: cover;
            }
            .turnBtn{
                width: 34px;
                height: 48px;
                background: rgba(0,0,0,.8);
                cursor: pointer;
                line-height: 48px;
                color: #fff;
                text-align: center;
                font-size: 30px;
                position: absolute;
                top: 280px;
                left: 0;
                z-index: 2;
            }
            .tjh-turnBtn-R{
                right: 0;
                left: auto;
            }
        }
        .swiperBox-yd{
            display: none;
        }
        .swiperBox{
            display: block;
            width: 1045px;
            margin: 30px auto;
            position: relative;
            .swiper-container-thrumbs{
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .swiper-slide-thumb-active{
                opacity: 1!important;
            }
            .swiper-slide{
                height: 115px;
                cursor: pointer;
                opacity: 0.4;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
        }
    }
}

// 往期回顾
.previousReview{
    width: 1342px;
    margin-top: 50px;
    :global{
        .title{
            font-size: 36px;
            font-weight: 600; 
            text-align: center;
            line-height: 100px;
            position: relative;
            .link{
                position: absolute;
                right: 20px;
                top: 40px;
                color: #fff;
                background-color: rgb(0, 161, 180);
                font-size: 18px;
                padding: 3px 10px;
                border-radius: 5px;
                font-weight: normal;
                line-height: 24px;
            }
        }
        .conBox{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .conItem{
                width: 655px;
                height: 188px;
                display: flex;
                align-items: center;
                cursor: pointer;
                color: #333333;
                justify-content: space-between;
                margin-bottom: 20px;
                img{
                    width: 285px;
                    height: 100%;
                    object-fit: cover;
                }
                .msgBox{
                    width: 358px;
                    .title{
                        text-align: left;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 40px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .msg{
                        font-size: 16px;
                        color: #999999;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient:vertical;
                    }
                    .ckxq{
                        margin: 10px 0;
                    }
                }
            }
        }
    }
   
}


@media (max-height: 850px) {
    .floatImg, .floatImg1 {
        top: 70vh;
    }
}


// 移动端
@media (max-width: 750px) {
.curatorSession{
    width: 7.5rem;
    margin: 0 auto;
    background-color: #fafafa;
    :global{
        p{
            margin: 0;
            padding: 0;
        }
    }  
}
.title_type_1{
    font-size:.32rem;
    font-weight:bold;
    line-height:.32rem;
    color:#fff;
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0;
    text-indent:0;
    .i_small2{
        width:.16rem;
        height:.16rem;
        background:#f55c5d;
    }
    .i_large2{
        width:.3rem;
        height:.3rem;
        background:#00a1b4;   
        margin-right:.07rem;             
    }            
    span{
        padding:.1rem .4rem;
        background:#00a1b4;
        border-radius:5px;
        margin:0 .16rem 0 0;
        box-sizing:border-box;
    }
}
.indexCrumbs{
    width: 100vw;
    height: .6rem;
    background-color: #f2f2f2;
    :global{
        .crumbsCon{
            font-size: .24rem;
            padding-left: .2rem;
            width: 100%;
            height: .6rem;
            line-height: .6rem;
            margin: 0 auto;
            text-align: left;
        }
    }
}
.floatImg,.floatImg1{
    display: none;
}
// 顶部轮播
.topSwiper{
    width: 100%; 
    display: block;
    height: auto;
    margin: 0 auto;
    :global{
        .swiperBox{
            width: 100%;
            position: relative;
            .turnBtn{
               width: .34rem;
               height: .50rem;
               line-height: .50rem;
               font-size: .3rem;
               top: 1.9rem;
            }
            .topSwiper-slide{
                height: 4.23rem;
                .curatorTopSwText{
                    height: .55rem;
                    font-size: .24rem;
                    line-height: .55rem;
                    padding: 0 .20rem;
                }
            }
            .ant-carousel .slick-dots-bottom{
                bottom: .35rem;
                right: .2rem;
            }
            .ant-carousel .slick-dots li button{
                width: .1rem;
                height: .1rem;
                border-radius: .05rem;
            }
        }
        .topSwiperMsgBox{
            width: 100%;
            height: auto;
            padding: .2rem;
            .topSwiperMsg-title{
                font-size: .3rem;
                margin-bottom: .20rem;
                font-weight: 600;
            }
            .topSwiperMsg-msg{
                font-size: .26rem;
            }
        }
    }
    
}

    // 新闻中心
.curatorNewsCenter{
   height: auto;
   background: none;
    :global{
        .title{
            font-size: .36rem;
            margin: .3rem auto;
            .link{
                right: 0.2rem;
                top: 0.4rem;
                font-size: 0.18rem;
                padding: 0.03rem 0.1rem;
                border-radius: 0.05rem;
                line-height: 0.24rem;
            }
        }
        .btnItem{
            width: 1.6rem;
            height: .45rem;
            border-radius: .225rem;
            line-height: .45rem;
            margin: 0 .2rem;
            font-size: .24rem;
        }
        .btnItem-active,
        .btnItem:hover{
            background-color: #0c81c7;
            color: #fff;
        }
        .contentBox{
            width: 100%;
            margin: .28rem auto;
            height: auto;
            display: block;
            .conLeft{
                width: 7.15rem;
                margin: 0 auto;
                .leftItem{
                    width: 3.5rem;
                    height: 3.9rem;
                    img{
                        height: 2.2rem;
                    }
                    .msgBox{
                        width: 100%;
                        height: 1.7rem;
                        padding: .1rem;
                        .title{
                            font-size: .26rem;
                            width: 100%;
                            overflow: hidden;
                        }
                        .msg{
                            text-align: justify;
                            font-size: .22rem;
                            color: #666666;
                            margin: .1rem auto;
                        }
                        .flex-jus{
                            font-size: .2rem;
                        }
                    }
                }
            }
            .conRight{
                width: 7.08rem;
                margin: .2rem auto;
                padding: 0 .26rem;
                .rightItem{
                    padding: .2rem 0;
                    .dateBox{
                        width: 1.08rem;
                        height: 1.08rem;
                        font-size: .2rem;
                        .bigSize{
                            font-size: .48rem;
                        }
                    }
                    .title{
                        width: 4.32rem;
                        font-size: .26rem;
                    }
                    .btn{
                        width: .45rem;
                        height: .45rem;
                        border-radius: .225rem;
                        line-height: .45rem;
                    }
                }
            }
        }
    }
}

// 视频选择框
.videoSelect{
    width: 100%;
    height: auto;
    margin: .2rem auto;
    display: block;

    :global{
        .videoBox{
            width: 100%;
            height: 4.2rem;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .videoList{
            width: 7.5rem;
            display: flex;
            flex-wrap: wrap;
            margin: 0.4rem auto; 
            .iconImg{
                color: #0c81c7;
                position: absolute;
                font-size: 25px;
                left: .65rem;
                transform: rotate(90deg);
                top: -0.25rem;
                display: none;
            }
            .videoItem{
                position: relative;
                width: 1.7rem;
                height: .5rem;
                font-size: .22rem;
                line-height: .5rem;
                padding:0;
                margin: .2rem .085rem;
                
                p{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                img{
                    width: .25rem;
                    // height: 30px;
                    margin-right: .1rem;
                }
            }
        }
    }
}


// 发言嘉宾
.honouredGuest{
    width: 100%;
    height: 5rem;
    margin: .3rem auto 0;
    :global{
        .title{
            font-size: .36rem;
            line-height: 1rem;
            .link{
                right: 0.2rem;
                top: 0.4rem;
                font-size: 0.18rem;
                padding: 0.03rem 0.1rem;
                border-radius: 0.05rem;
                line-height: 0.24rem;
            }
        }
        .turnBtn{
            width: .46rem;
            height: .6rem;
            line-height: .6rem;
            font-size: .3rem;
            top: 2.6rem;
        }
        .swiperBox{
            display: none;
        }
        .swiperBox-yd{
            display: block;
            width: 7.08rem;
            height: 3.85rem;
            margin: 0 auto;
            overflow: hidden;
            .swiper-slide{
                width:35%;
                height: 3.85rem!important;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .text{
                    background-color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: .55rem;
                    line-height: .23rem;
                    padding-top: .05rem;
                    text-align: center;
                    font-size: .24rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
// 推荐会
.sellMetting{
    width: 100%;
    :global{
        .banner{
            width: 100%;
        }
        .title{
           font-size: .36rem;
           line-height: 1.2rem;
           .link{
            right: 0.2rem;
            top: 0.4rem;
            font-size: 0.18rem;
            padding: 0.03rem 0.1rem;
            border-radius: 0.05rem;
            line-height: 0.24rem;
        }
        }
        .bigImg,.swiperBox{
            display: none!important;
        }
        .bigImg-yd{
            display: block;
            width: 7.08rem;
            height: 5.8rem;
            margin: 0 auto;
            position: relative;
            .big-yd{
                width: 100%;
                height: 100%;
                overflow: hidden;
            }
            .swiper-slide img{
                width: 7.08rem;
                height: 5.8rem;
                object-fit: cover;
            }
            .turnBtn{
                width: .4rem;
                height: .58rem;
                background: rgba(0,0,0,.8);
                cursor: pointer;
                line-height: .58rem;
                color: #fff;
                text-align: center;
                font-size: .3rem;
                position: absolute;
                top:2.5rem;
                left: 0;
                z-index: 2;
            }
            .tjh-yd-turnBtn-R{
                right: 0;
                left: auto;
            }
        }
        .swiperBox-yd{
            display: block;
            width: 7.08rem;
            margin: .2rem auto;
            position: relative;
            overflow: hidden;
            .swiper-slide {
                opacity: .4;
                img{
                    width: 100%;
                    height:1.5rem;
                    object-fit: cover;
                }
            }
            .swiper-slide-thumb-active{
                opacity: 1!important;
            }
        }
    }
}

// 往期回顾
.previousReview{
    width: 7.08rem;
    margin: 0 auto;
    :global{
        .title{
            font-size: .36rem;
            line-height: 1rem;
            .link{
                right: 0.2rem;
                top: 0.4rem;
                font-size: 0.18rem;
                padding: 0.03rem 0.1rem;
                border-radius: 0.05rem;
                line-height: 0.24rem;
            }
        }
        .conBox{
            display: block;
            .conItem{
                color: #333333;
                width: 7.08rem;
                height: 2rem;
                img{
                    width: 3rem;
                    height: 100%;
                    object-fit: cover;
                }
                .msgBox{
                    width: 3.94rem;
                    font-size: .22rem;
                    .title{
                        font-size: .26rem;
                        line-height: .5rem;
                    }
                    .msg{
                        font-size: .22rem;
                    }
                    .ckxq{
                        font-size: .22rem;
                        margin: .1rem 0;
                    }
                }
            }
        }
    }
   
}
}
.flexBox-sb{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexBox-cen{
    display: flex;
    justify-content: center;
    align-items: center;
}


// pc端
@media (min-width: 750px) {
    .curatorSession{
        width: 1240px;
        margin: 40px auto;
        color: #333;
        text-align: justify;
        :global{
            p{
                margin: 0;
                padding: 0;
            }
            .conBox{
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                .conItem{
                    width:395px;
                    margin-right: 27px;
                    margin-bottom: 25px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 250px;
                        object-fit: cover;
                    }
                    .title{
                        width: 100%;
                        height: 50px;
                        font-size: 16px;
                        margin:15px 0;
                        display: -webkit-box;  
                        -webkit-line-clamp: 2;  
                        -webkit-box-orient: vertical;    
                        overflow: hidden;  
                        text-overflow: ellipsis;  
                        word-wrap: break-word; 
                    }
                    .time{
                        color: #999;
                    }
                }
                .conItem:nth-of-type(3n){
                    margin-right: 0;
                }
            }
        }
    }
    
    //详情页
    .contentStyle{
        width: 1240px;
        margin: 50px auto;
        :global{
            .title{
                font-size: 30px;
                margin: 50px auto 0px;
                text-align: center;
            }
            .time{
                margin: 20px auto;
                text-align: center;
                padding-bottom: 30px;
                border-bottom: 1px solid #dddddd;
            }
            .main{
                width: 100%;
                p{
                    font-size: 16px;
                    text-indent: 2em;
                }
                img{
                    max-width: 800px;
                    margin: 30px auto;
                    display: block;
                }
                
                
            }
            .ljfhBtn{
                width: 100px;
                height: 40px;
                color: #fff;
                font-size: 18px;
                background: rgb(0, 161, 180);
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                border-radius: 5px;
                margin: 40px auto;
            }
            .listBox{
                padding: 50px 0;
                position: relative;
                border-top: 1px solid #dddddd;
                p{
                    cursor: pointer;
                }
                
            }
        }
    }
}


// 移动端
@media (max-width: 750px) {
    .curatorSession{
        width: 7.3rem;
        overflow: hidden;
        margin: .4rem auto;
        color: #333;
        text-align: justify;
        :global{
            p{
                margin: 0;
                padding: 0;
            }
            .conBox{
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                .conItem{
                    width: 3.5rem;
                    margin-right: 0.3rem;
                    margin-bottom: .35rem;
                    cursor: pointer;
                    img{
                        width: 100%;
                        border-radius: .08rem;
                        height: 2.1rem;
                        object-fit: cover;
                    }
                    .title{
                        width: 100%;
                        height: .82rem;
                        font-size: .28rem;
                        margin:.15rem 0;
                        display: -webkit-box;  
                        -webkit-line-clamp: 2;  
                        -webkit-box-orient: vertical;    
                        overflow: hidden;  
                        text-overflow: ellipsis;  
                        word-wrap: break-word; 
                    }
                    .time{
                        color: #999;
                    }
                }
                .conItem:nth-of-type(2n){
                    margin-right: 0;
                }
            }
        }
    }
    
    //详情页
    .contentStyle{
        width: 100%;
        margin: .5rem auto;
        :global{
            .title{
                font-size:.35rem;
                margin: .3rem auto 0px;
                text-align: center;
            }
            .time{
                margin: .2rem auto;
                text-align: center;
                padding-bottom: .3rem;
                border-bottom: 1px solid #dddddd;
            }
            .main{
                width: 100%;
                p{
                    font-size: .28rem;
                    text-indent: 2em;
                }
                img{
                    //max-width: 100%;
                    width: 100%!important;
                    height: auto!important;
                    margin: .3rem auto;
                    display: block;
                }
                
            }
            .ljfhBtn{
                width: 1.5rem;
                height: .6rem;
                color: #fff;
                font-size: .28rem;
                background: rgb(0, 161, 180);
                line-height: .6rem;
                text-align: center;
                cursor: pointer;
                border-radius: .05rem;
                margin: 0.2rem auto;
            }
            .listBox{
                padding: 50px 0;
                position: relative;
                border-top: 1px solid #dddddd;
                p{
                    cursor: pointer;
                }
               
            }
        }
    }
}
html {
    color: #111;
    background: #fff;
    /* filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(100%); */
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
blockquote {
    margin: 0;
    padding: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

fieldset,
img {
    border: 0
}

address,
caption,
cite,
code,
dfn,
em,
i,
strong,
th,
var,
optgroup {
    font-style: normal;
    font-weight: normal
}
strong{
    font-weight: bold!important;
}
ul,
ol {
    list-style: none
}

caption,
th {
    text-align: left
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: normal
}

q:before,
q:after {
    content: ""
}

abbr,
acronym {
    border: 0;
    font-variant: normal
}

sup {
    vertical-align: baseline
}

sub {
    vertical-align: baseline
}

legend {
    color: #000
}
input,
button,
textarea,
select,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit
}

input,
button,
textarea,
select {
    *font-size: 100%
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word
}

a {
    cursor: pointer
}

a:link {
    text-decoration: none
}

a img {
    border-width: 0;
    vertical-align: middle
}

body,
td,
th {
    font: 12px Helvetica, Arial, sans-serif;
    line-height: 1.62
}

table {
    border-collapse: collapse;
    border: none;
    padding: 0;
    margin: 0
}
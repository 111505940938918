.union{
    width: 1342px;
    margin: 0 auto;
    :global{
        p{
            margin: 0;
            padding: 0;
        }
    }
}

.diaBox{
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 2000000000000000;
    display: flex;
    justify-content: center;
    align-items: center;
    :global{
        .tst{
            display: block;
            height: 80vh;
        }
        .close{
            width: 60px;
            height: 60px;
            align-self: flex-start;
            margin-top: 10vh;
            cursor: pointer;
        }
    }
}

.indexCrumbs{
    width: 100%;
    height: 50px;
    background: none;
    :global{
    .crumbsCon{
            width: 100%;
            height: 50px;
            line-height: 50px;
            margin: 0 auto;
            text-align: left;
            font-size: 18px;
            span{
                color: rgb(0, 161, 180);
            }
        }
    }
}
//悬浮按钮
.floatImg1,
.floatImg{
    position: fixed;
    top: 40vh;
    width: 130px;
    height: 130px;
    cursor: pointer;
    z-index: 99;
}
.floatImg{
    left: 50%;
    margin-left: -810px;
}
.floatImg1{
    left: 50%;
    margin-left: 680px;
}

.title_type_1{
    font-size:28px;
    font-weight:bold;
    line-height:28px;
    color:#fff;
    display:flex;
    flex-direction:row;
    align-items:center;
    .i_small2{
        width:8px;
        height:8px;
        background:#f55c5d;
    }
    .i_large2{
        width:16px;
        height:16px;
        background:#00a1b4;   
        margin-right:7px;             
    }            
    span{
        padding:10px 25px;
        background:#00a1b4;
        border-radius:5px;
        margin:0 16px 0 0;
        box-sizing:border-box;
    }
}
// topSwiper
.topSwiper{
    width: 100%; 
    height: 425px;
    background-color: #f7f7f7;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    :global{
        .swiperBox{
            width: 841px;
            position: relative;
            .turnBtn{
                width: 35px;
                height: 50px;
                background: rgba(0,0,0,.5);
                cursor: pointer;
                color: #fff;
                line-height: 50px;
                text-align: center;
                font-size: 30px;
                position: absolute;
                top: 190px;
                z-index: 2;
            }
            .turnBtn-l{
                left: 0;
            }
            .turnBtn-R{
                right: 0;
            }
            .topSwiper-slide{
                height: 425px;
                position: relative;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .curatorTopSwText{
                    width: 100%;
                    height: 55px;
                    background: rgba(0,0,0,.5);
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    color: #fff;
                    font-size: 18px;
                    line-height: 55px;
                    box-sizing: border-box;
                    padding: 0 20px;
                }
            }
            .ant-carousel .slick-dots-bottom{
                text-align: right;
                bottom: 30px;
                right: 20px;
            }
            .ant-carousel .slick-dots li button{
                width: 10px;
                height: 10px;
                border-radius: 5px;
            }
        }
        .topSwiperMsgBox{
            width: 501px;
            height: 100%;
            box-sizing: border-box;
            padding: 20px;
            span{
                line-height: 34px;
            }
            .topSwiperMsg-title{
                font-size: 30px;
                text-align: center;
                margin-bottom: 20px;
                color: #333333;
            }
            .topSwiperMsg-msg{
                width: 95%;
                text-align: justify;
                // word-break: break-all;
                hyphens: auto;
                font-size: 19px;
                line-height: 30px;
                color: rgb(0, 0, 0);
                margin-top: 10px;
                overflow: hidden; /* 隐藏超出内容 */  
                text-overflow: ellipsis; /* 超出时显示省略号 */  
                display: -webkit-box; /* 设置为多行显示 */
                -webkit-line-clamp: 7; /* 这里设置你希望显示的行数 */  
                -webkit-box-orient: vertical; 
            }
        }
    }
    
}

// 主要职能
.mainFunc{

    width: 1342px;
    height: 120px;  
    margin: 60px auto;
    display: flex;
    :global{
        .funcListYd{
            display: none;
        }
        .funcList {  
            width: 100%;  
            // position: relative;  
            overflow: hidden;
            .func{
                // position: absolute;  
                width: 260px;  
                height: 120px;  
                background-size: cover;  
                background-position: center;  
                // box-sizing: border-box; 
                // background-image: url('/img/union/func_bg.png');
                background: #00a1b4;
                margin-right: 10px;  
                cursor: pointer;
                float :left;
                .text {  
                    // position: absolute;  
                    width: 100%;  
                    height: 120px;  
                    padding: 19px 0;
                    font-size: 29px;  
                    font-family: DIN, DIN-Medium;  
                    font-weight: 500;  
                    color: #fff;  
                    line-height: 40px;  
                    // left: 16px;  
                    // top: 56px;  
                    overflow: hidden; /* 隐藏超出容器的内容 */  
                    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */  
                    display: -webkit-box; /* 旧版 Safari 和 Chrome */  
                    -webkit-line-clamp: 2; /* 限制显示的行数 */  
                    -webkit-box-orient: vertical; /* 设置文本方向为垂直 */  
                    text-align: center;
                }
            }
            // .func:nth-child(1) {  
            //     left: 0;  
            // }  
                
            // .func:nth-child(2) {  
            //     left: 261px; /* 304px - 43px */  
            // }  
                
            // .func:nth-child(3) {  
            //     left: 522px; /* 2 * (304px - 43px) */  
            // }  
                
            // .func:nth-child(4) {  
            //     left: 783px; /* 3 * (304px - 43px) */  
            // }  
                
            .func:nth-child(5) {  
                // left: 1044px; /* 4 * (304px - 43px) */  
                margin-right: 0;
            }  
        }  
    }
   
}

// 场馆
.venue{
    width: 1342px;
    height: 1120px;
    background-color: #d9f1ff;
    :global{
        .list{
            .title{
                font-size: 30px;
                font-weight: 600; 
                text-align: center;
                line-height: 40px;
                position: relative;
                padding: 15px 0;
                .link{
                     position: absolute;
                     right: 25px;
                     top: 65px;
                     color: #fff;
                     background-color: rgb(0, 161, 180);
                     font-size: 18px;
                     padding: 3px 10px;
                     border-radius: 5px;
                     font-weight: normal;
                     line-height: 24px;
                 }
             }
             .item {  
                 display: flex;  
                 flex-direction: column;  
                 justify-content: center; 
                 align-items: flex-start;  
                 width: 419px;
                 height: 320px;
                 margin-left: 20px; 
                 margin-bottom: 20px;  
                 cursor: pointer; 
                 position: relative; 
                 transition: opacity 0.3s ease;   
                 opacity: 1; 
                 float: left;
                 &::after {  
                    content: ""; /* 创建一个伪元素作为遮罩层 */  
                    position: absolute; /* 绝对定位使其覆盖整个.item */  
                    top: 0;  
                    left: 0;  
                    right: 0;  
                    bottom: 0;  
                    background-color: rgba(0, 0, 0, 0); /* 初始透明度为0，即完全透明 */  
                    transition: background-color 0.3s ease; /* 过渡效果 */  
                    pointer-events: none; /* 遮罩层不接收鼠标事件，确保点击事件可以穿透到.item */  
                }  
                &:hover::after {  
                    background-color: #000; /* 悬停时变为半透明黑色，遮住图片 */ 
                    // 透明
                    opacity: 0.3;
                }
                 img {
                     width: 100%;
                     height: 100%;
                     object-fit: cover
                 }
                 &:hover .title{  
                     opacity: 1; 
                     z-index: 1;
                 } 
                 .title {  
                     position: absolute;  
                     left: 50%;  
                     transform: translateX(-50%); 
                     opacity: 0;  
                     transition: opacity 0.3s ease;  
                     width: 95%; 
                     height: auto;
                     font-size: 30px;  
                     font-family: SourceHanSansCN, SourceHanSansCN-Bold;  
                     font-weight: 700;  
                     color: #feffff;  
                    //  line-height: normal; 
                    //  overflow: hidden; 
                    //  text-overflow: ellipsis; 
                    //  white-space: nowrap; 
                 }
             }
             .bigImg-yd,.swiperBox-yd{
                 display: none;
             }
             .swiperBox-yd{
                 display: none;
             }
        }
        
    }
}

// 最新资讯
.newsCenter {
    width: 1342px;
    // height: 581px;
    // margin:145px 0;
    padding: 0 45px;
    :global{
        .contentYd{
            display: none;
        }
        .content{
            .zxzx{
                width: 534px;
                // height: 581px;
                border: 2px solid #00a1b4;
                border-radius: 11px;
                float: left;
                margin: 145px 0;
                .new_title {
                    width: 491px;
                    height: 55px;
                    line-height:35px;
                    font-size: 30px;
                    color: #00A1B4;
                    margin: 8px auto 0px;
                    font-weight: bold;
                    // border-bottom: 2px solid rgb(221, 242, 245);
                    display: block;
                }
                .zxzx_list {
                width: 491px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 20px auto;
                    .zxzx_link {
                        width: 475px;
                        line-height: 45px;
                        height: 45px;
                        font-size: 16px;
                        color: #3a4045;
                        margin-left: 9px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        // display: flex;
                        align-items:center;
                        span{
                        width:16px;
                        img{
                            width:16px;
                        }
                        }
                    }
                    .more {
                        margin-left: 451px;
                        font-size: 18px;
                        color: #00a1b4;
                    }
                }
            }
            .zxzx:nth-child(1) {
                margin-right: 184px;
            }
        }
    }
}



@media (max-height: 850px) {
    .floatImg, .floatImg1 {
        top: 70vh;
    }
}


// 移动端
@media (max-width: 750px) {
.union{
    width: 7.5rem;
    margin: 0 auto;
    background-color: #fafafa;
    :global{
        p{
            margin: 0;
            padding: 0;
        }
    }  
}
.title_type_1{
    font-size:.32rem;
    font-weight:bold;
    line-height:.32rem;
    color:#fff;
    display:flex;
    flex-direction:row;
    align-items:center;
    margin:0;
    text-indent:0;
    .i_small2{
        width:.16rem;
        height:.16rem;
        background:#f55c5d;
    }
    .i_large2{
        width:.3rem;
        height:.3rem;
        background:#00a1b4;   
        margin-right:.07rem;             
    }            
    span{
        padding:.1rem .4rem;
        background:#00a1b4;
        border-radius:5px;
        margin:0 .16rem 0 0;
        box-sizing:border-box;
    }
}
.indexCrumbs{
    width: 100vw;
    height: .6rem;
    background-color: #f2f2f2;
    :global{
        .crumbsCon{
            font-size: .24rem;
            padding-left: .2rem;
            width: 100%;
            height: .6rem;
            line-height: .6rem;
            margin: 0 auto;
            text-align: left;
        }
    }
}
.floatImg,.floatImg1{
    display: none;
}
// 顶部轮播
.topSwiper{
    width: 100%; 
    display: block;
    height: auto;
    margin: 0 auto;
    :global{
        .swiperBox{
            width: 100%;
            position: relative;
            .turnBtn{
               width: .34rem;
               height: .50rem;
               line-height: .50rem;
               font-size: .3rem;
               top: 1.9rem;
            }
            .topSwiper-slide{
                height: 4.23rem;
                .curatorTopSwText{
                    height: .55rem;
                    font-size: .24rem;
                    line-height: .55rem;
                    padding: 0 .20rem;
                }
            }
            .ant-carousel .slick-dots-bottom{
                bottom: .35rem;
                right: .2rem;
            }
            .ant-carousel .slick-dots li button{
                width: .1rem;
                height: .1rem;
                border-radius: .05rem;
            }
        }
        .topSwiperMsgBox{
            width: 100%;
            height: auto;
            padding: .2rem;
            .topSwiperMsg-title{
                font-size: .3rem;
                margin-bottom: .20rem;
                font-weight: 600;
            }
            .topSwiperMsg-msg{
                font-size: .26rem;
            }
        }
    }
    
}

// 主要职能
.mainFunc {  
    width: 100%;  
    height: auto;  
    margin: 0.2rem auto;  
    display: block;  
    box-sizing: border-box; /* 确保padding和border不会增加元素的总宽度 */  
    padding: 0.2rem; /* 增加一些内边距以提供更好的视觉效果 */  
    :global{
        .funcList{
            display: none;
        }
        .funcListYd {  
            width: 100%;  
            height: auto; /* 根据内容自动调整高度 */  
            margin: 0 auto;  
            display: flex; /* 使用flex布局以更好地在移动端展示列表项 */  
            flex-wrap: wrap; /* 允许列表项换行 */  
            justify-content: space-between; /* 在主轴上平均分布列表项 */  
            flex-direction: column;
            .func {  
                width: 100%; /* 每行显示两个列表项，每个列表项占50%减去一些间距 */  
                height:auto;
                margin-bottom: 0.3rem; /* 增加底部外边距以分隔列表项 */  
                // box-sizing: border-box;  
                // padding: 0.1rem;  
                // background-color: #fff; /* 添加背景色以提供更好的视觉效果 */  
                border-radius: 0.1rem; /* 圆角使元素看起来更圆润 */  
                box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1); /* 添加阴影以突出列表项 */ 
                background: #00a1b4;
                .text {  
                    font-size: 0.36rem; /* 保持字体大小不变 */  
                    text-align: center; /* 文本居中显示 */   
                    color: #fff;  
                    line-height: 1rem;  
                    overflow: hidden; /* 隐藏超出容器的内容 */  
                    text-overflow: ellipsis; /* 当文本溢出时显示省略号 */  
                    display: -webkit-box; /* 旧版 Safari 和 Chrome */  
                    -webkit-line-clamp: 2; /* 限制显示的行数 */  
                    -webkit-box-orient: vertical; /* 设置文本方向为垂直 */ 
                }   
            }  

        }  
    }
}  


// 场馆
.venue{
    width: 100%;
    height:7.36rem;
    :global{
        .list{
            .title{
                font-size: 0.35rem;
                line-height: 0.5rem;
                 .link{
                     right: 0.27rem;
                     top: 0.8rem;
                    
                 }
             }
             .item {  
                 width: 2.38rem;
                 height: 1.82rem;
                 margin-left: 0.08rem; 
                 margin-bottom: 0.08rem;  
                 .title{
                     font-size: 0.24rem;
                 }
             }
        }
        
    }
}

.newsCenter{
    width:100%;
    height: auto;
    margin: 0;
    padding: 0;
    :global{
        .content{
            display: none;
        }
        .contentYd{
            display: block;
            .news_wrap {
                width: 100%;
                overflow: hidden;
                .news_content {
                  width: 100%;
                  overflow: hidden;
                  .news_part_1 {
                    width: 100%;
                    background: #00A1B4;
                    padding: 0.3rem 0.2rem;
                    box-sizing: border-box;
                    .news_part_1_tab {
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      font-weight: bold;
                      line-height: 0.52rem;
                      font-size: 0.32rem;
                      justify-content: center;
                      .news_tab_1 {
                        width: 2.82rem;
                        height: auto;
                        background: url(/administrator/data/upload/portal/resource/img/home/tags_9.png) 0 0 no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                        text-align: center;
                      }
                      .news_tab_2 {
                        width: 2.82rem;
                        height: auto;
                        background: url(/administrator/data/upload/portal/resource/img/home/tags_10.png) 0 0 no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                        margin-left: -0.2rem;
                        text-align: center;
                      }
                      .news_tab_1_active {
                        width: 2.82rem;
                        height: auto;
                        background: url(/administrator/data/upload/portal/resource/img/home/home_26.png) 0 0 no-repeat;
                        background-size: 100% 100%;
                        color: #00A1B4;
                        text-align: center;
                      }
                      .news_tab_2_active {
                        width: 2.82rem;
                        height: auto;
                        background: url(/administrator/data/upload/portal/resource/img/home/home_19.png) 0 0 no-repeat;
                        background-size: 100% 100%;
                        color: #00A1B4;
                        margin-left: -0.2rem;
                        text-align: center;
                      }
                    }
                    .hdyg {
                      width: 6.1rem;
                      margin: 0rem auto 0;
                      .hdyg_list {
                        width: 100%;
                        overflow: hidden;
                        .hdyg_link {
                          width: 100%;
                          padding: 0.43rem 0 0.23rem;
                          box-sizing: border-box;
                          border-bottom: 0.02rem solid rgba(255,255,255,0.5);
                          display: block;
                          .time {
                            font-size: 0.28rem;
                            color: #fff;
                            text-align: left;
                            margin-top: 0.3rem;
                            text-indent: 0.39rem;
                          }
                          .article {
                            display: flex;
                            flex-direction: row;
                            i {
                              width: 0.19rem;
                              height: 0.19rem;
                              border-radius: 100%;
                              background: #fff;
                              display: inline-block;
                              margin-right: 0.2rem;
                              margin-top: 0.12rem;
                            }
                            img{
                              width:.3rem;
                              height:.3rem;
                              margin-top: .1rem;
                            }
                            span {
                              flex: 1;
                              line-height: 0.44rem;
                              font-size: 0.3rem;
                              color: #fff;
                            }
                          }
                        }
                      }
                    }
                    .tzgg {
                      width: 6.1rem;
                      margin: 0rem auto 0;
                      .tzgg_list {
                        width: 100%;
                        overflow: hidden;
                        .hdyg_link {
                          width: 100%;
                          padding: 0.43rem 0 0.23rem;
                          box-sizing: border-box;
                          border-bottom: 0.02rem solid rgba(255,255,255,0.5);
                          display: block;
                          .time {
                            font-size: 0.28rem;
                            color: #fff;
                            text-align: left;
                            margin-top: 0.3rem;
                            text-indent: 0.39rem;
                          }
                          .article {
                            display: flex;
                            flex-direction: row;
                            i {
                              width: 0.19rem;
                              height: 0.19rem;
                              border-radius: 100%;
                              background: #fff;
                              display: inline-block;
                              margin-right: 0.2rem;
                              margin-top: 0.12rem;
                            }
                            img{
                              width:.3rem;
                              height:.3rem;
                              margin-top: .1rem;
                            }
                            span {
                              flex: 1;
                              line-height: 0.44rem;
                              font-size: 0.3rem;
                              color: #fff;
                            }
                          }
                        }
                      }
                    }
                  }
                    }
              }
        }
    }
}


}
@media (min-width: 750px) {
    .shareBox{
        display: flex;
        align-items: center;
        :global{
            .shareItem{
                width: 30px;
                height: 30px;
                background-color: #fff;
                border-radius: 25px;
                cursor: pointer;
                margin-left: 10px;
                position: relative;
                img{
                  width: 100%;
                //   margin-top: 10px;
                  display: block;
                }
                .shareEwm{
                    padding: 5px;
                    background-color: #fff;
                    position: absolute;
                    z-index: 20000000000;
                    top: 40px;
                    left: -50px;
                    canvas{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }  
    }
    
    
}

// 移动端
@media (max-width: 750px) {
.shareBox{
    display: none; 
}

}
@media (min-width: 750px){
    .topBanner{
        width: 100%;
        margin: 0 auto 20px;
        background-color: rgb(240, 240, 240);
        border-radius: 8px;
        justify-content: flex-start;
        padding: 20px;
        box-sizing: border-box;
        :global{
            img{
                width:  610px;
                height: 350px;
                object-fit: cover;
                border-radius: 8px;
                margin-right: 30px;
            }
            .right{
                width: 555px;
                height: 350px;
                box-sizing: border-box;
                padding: 50px 0;
                .title{
                    font-size: 30px;
                    font-weight: 600;
                    margin-bottom: 35px;
                    color: #333;
                }
                p{
                    color: #999;
                    text-align: justify;
                    font-size: 18px;
                }
            }
            p{
                margin: 0;
                padding: 0;
            }
        }
    }
}


@media (max-width: 750px){
    .topBanner{
        width: 100%;
        margin: 0 auto .2rem;
        display: block;
        :global{
            img{
                width: 100%;
                height: 4rem;
                object-fit: cover;
                border-radius: .08rem;
            }
            .right{
                width: 100%;
                // height: 400px;
                box-sizing: border-box;
                padding: .2rem 0;
                .title{
                    font-size: .35rem;
                    font-weight: 600;
                    margin-bottom:.1rem;
                    color: #333;
                }
                p{
                    color: #999;
                    text-align: justify;
                    font-size: .28rem;
                }
            }
            p{
                margin: 0;
                padding: 0;
            }
        }
    }
}


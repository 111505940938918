

@media (min-width: 750px){
    .crumbsBox{
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;
        font-size: 16px;
        color: rgb(0, 161, 180);
        :global{
            p{
                margin-right: 12px;
                cursor: pointer;
                &:nth-last-child(1){
                    cursor: default;
                    span{
                        display: none;
                    }
                }
            }
        }
    }    
}



@media (max-width: 750px){
    .crumbsBox{
        width: 100%;
        margin: 0 auto 20px;
        display: flex;
        flex-wrap: wrap;
        font-size: .28rem;
        color: rgb(0, 161, 180);
        :global{
            p{
                margin-right: .12rem;
                cursor: pointer;
                &:nth-last-child(1){
                    cursor: default;
                    span{
                        display: none;
                    }
                }
            }
        }
    }    
}
@media (min-width: 750px){
    .titleBox{
        width: 100%;
        margin: 80px auto 30px;
        position: relative;
        :global{
            .hon{
                width: 8px;
                height: 8px;
                background-color: rgb(245, 92, 93);
            }
            .lv{
                width: 16px;
                height: 16px;
                background: rgb(0, 161, 180);
                margin: 0 8px;
            }
            .title{
                padding: 2px 35px;
                background: rgb(0, 161, 180);
                border-radius: 5px;
                margin: 0px 10px;
                font-size: 32px;
                color: #fff;
                font-weight: 600;
            }
            .gdBtn{
                font-size: 16px;
                color: #999;
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
            }
        }
    }
}




@media (max-width: 750px){
    .titleBox{
        width: 100%;
        margin: .3rem auto;
        position: relative;
        :global{
            .hon{
                width: .1rem;
                height: .1rem;
                background-color: rgb(245, 92, 93);
            }
            .lv{
                width: .2rem;
                height: .2rem;
                background: rgb(0, 161, 180);
                margin: 0 .1rem;
            }
            .title{
                padding: .1rem .4rem;
                background: rgb(0, 161, 180);
                border-radius: .05rem;
                margin: 0px .1rem;
                font-size: .4rem;
                color: #fff;
                font-weight: 600;
            }
            .gdBtn{
                font-size: .28rem;
                color: #999;
                position: absolute;
                bottom: 0;
                right: 0;
                cursor: pointer;
            }
        }
    }
}
